<template>
  <b-container class="container-px-lg vacancies-page" v-if="vacancies.dataSource.items.length">
    <b-row>
      <b-col class="mt-1">
        <span style="color: gray">
          <router-link class="text-black" to="/">{{$t('crumbs.home')}}</router-link>
           / {{$t('crumbs.vacancies')}}
        </span>
      </b-col>
    </b-row>
    <q-header :centered="false" level="1" size="xxl" class="my-1 name_text">
      <b>
        {{$t("lending.vacancies.header")}}
        <span class="vacancy-ellipse position-relative">Qubity<img alt=""
          src="@/assets/images/site/vacancies_ellipsis.svg"></span>
      </b>
    </q-header>
    <i18n path="lending.vacancies.text" tag="span">
      <br>
    </i18n>
    <vacancy-card
        v-for="(vacancy, index) in vacancies.dataSource.items"
        :key="index"
        :attributes="vacancy.employments"
        :description="vacancy.content"
        :link="vacancy.hh_url"
        :img="vacancy.media.urls.original"
        :salary="vacancy.salary"
        :title="vacancy.header"
        :salary-from="vacancy.pay_from"
        :salary-to="vacancy.pay_to"
        class="name_small_text"
    />
    <div class="info_plus my-5">
      <p>{{$t("lending.vacancies.find-suitable")}} <b>info@qubity.io</b>
      </p>
      <img alt="" class="w-100 d-block d-sm-none" src="@/assets/images/site/happy-people.png">
      <img alt="" class="d-sm-block d-none" src="@/assets/images/site/happy-people.png">
    </div>
  </b-container>
</template>

<script>
import VacancyCard from "@/views/Main/main-design-v2/views/vacancy/components/VacancyCard";
import vacancies from "@/modules/contents/vacancies";

export default {
  name: "vacancy",
  metaInfo() {
    const title = this.$t('title-pages.vacancy');
    return {
      title: title,
      titleTemplate: null,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Узнавайте первыми о вакансиях Qubity и станьте частью команды'
        }
      ]
    }
  },
  components: {VacancyCard},
  data() {
    return {
      vacancies
    }
  },
  created() {
    if (vacancies.dataSource.items.length === 0) {
      vacancies.get()
    }
  }
}
</script>

<style lang="scss" scoped>
.vacancy-ellipse {
  z-index: 1;
}

.vacancy-ellipse {
  img {
    position: absolute;
    transform: scale(1, -1);
    z-index: -1;
    width: 110%;
    right: -5px;
  }
}

.name_small_text {
  margin-top: 30px;
}

.info_plus {
  background: var(--theme-accent-dark);
  border-radius: 48px;
  position: relative;
  height: 247px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info_plus img {
  padding-top: 20px;
  width: 402px;
}

.info_plus p {
  font-size: 32px;
  line-height: 140%;
  width: 70%;
  color: #FFFFFF;
  padding-left: 56px;
}

@media screen and (max-width: 993px) {
  .info_plus {
    height: 270px;
  }

  .info_plus p {
    padding-left: 32px;
    width: 80%;
    font-size: 24px;
  }
  .info_plus img {
    padding-top: 42px;
  }
}

@media screen and (max-width: 768px) {
  .info_plus {
    flex-direction: column;
    height: 381px;
  }

  .info_plus p {
    width: 100%;
    padding: 16px 20px 0 16px;
    font-size: 20px;
  }

  .info_plus img {
    position: absolute;
    bottom: -20px;
  }
}

@media screen and (max-width: 576px) {
  .info_plus img {
    bottom: -6%;
  }
}

@media screen and (max-width: 460px) {
  .info_plus img {
    bottom: -5%;
  }
}

.vacancies-page {
  margin-top: 24px;
}

</style>
