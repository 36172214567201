<template>
  <a :href="link" target="_blank" class="d-flex flex-column flex-lg-row vacancy_card pl-0">
    <b-col class="px-0 d-flex" cols="12" lg="4" xl="3">
      <img alt="" class="card_img" :src="img">
    </b-col>
    <b-col class="my-1 py-0 py-lg-1 my-lg-auto d-flex flex-column flex-lg-row" cols="12" lg="8" xl="9">
      <b-col class="px-2 d-lg-none d-block" cols="12" lg="8" style="height: 70%">
        <b-row class="d-lg-none d-block">
          <q-header :centered="false" color="#000" size="s">
            <b>{{ title }}</b>
          </q-header>
          <div class="text-black mb-0" style="margin-top: 5px; line-height: 1.5" v-html="description"></div>
        </b-row>
      </b-col>
      <b-col class="d-none d-lg-block" cols="12" lg="8" style="border-right: rgba(0,0,0,.1) 1px solid; height: 70%">
        <q-header :centered="false" color="#000" size="s">
          <b>{{ title }}</b>
        </q-header>
        <div class="text-black mb-0" style="margin-top: 5px; line-height: 1.5" v-html="description"></div>
      </b-col>
      <b-col class="px-2 px-lg-4 my-2 mt-lg-0" cols="12" lg="4">
        <b-row>
          <h4 class="card_main_text">
            <b>от {{ salaryFrom.formatMoney(0, "", ".") }} до {{ salaryTo.formatMoney(0, "", ".") }} ₽</b>
          </h4>
        </b-row>
        <b-row v-for="(attribute, index) in attributes" :key="index">
          <span class="" style="font-size: 95%; margin-bottom: 5px;">
            <img alt="" class="mr-1" src="@/assets/images/site/icons/shape.svg">{{ attribute.title }}
          </span>
        </b-row>
      </b-col>
    </b-col>
  </a>
</template>

<script>
import img from '@/assets/images/icons/cryptonews.png'

export default {
  name: "vacancy-card",
  props: {
    img: {type: String, default: img},
    link: {type: String, default: "https://hh.ru/1"},
    title: {type: String, default: "Специалист в команду поддержки"},
    description: {type: String},
    salaryFrom: {type: Number, default: 10000},
    salaryTo: {type: Number, default: 20000},
    attributes: {type: Array, default: ["Удаленно", "Частичная или полная занятость"]}
  }
}
</script>

<style lang="scss" scoped>
.vacancy_card {
  border-radius: 32px;
  box-shadow: -14.3363px 20px 52.8651px rgba(81, 78, 120, 0.08);
}

.card_img {
  border-radius: 32px 0 0 32px !important;
  width: 100%;
  object-fit: cover;
}

.card_price {
  padding-top: 20px;
  width: 40%;
}

.test_border {
  border-left: 1px solid rgba(120, 120, 120, .1);
  width: 1px;
  height: 80%;
  margin: auto 30px;
}

.card_price_text {
  padding-bottom: 25px;
}

.my-padding {
  padding-bottom: 15px;
}

@media screen and (max-width: 993px) {
  .card {
    flex-direction: column !important;
    min-height: 600px; /* От 600 до 820 */
  }

  .card_img {
    border-radius: 32px 32px 0 0 !important;
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }

  .card_text {
    padding: 20px 32px;
  }

  .test_border {
    border-top: 1px solid rgba(120, 120, 120, .1);
    width: 90%;
    height: 1px;
    margin: 0 auto;
  }

  .card_price {
    width: 100%;
    padding: 20px 32px;
  }

  .my-padding {
    padding-bottom: 10px;
    margin-bottom: 0 !important;
  }
}
</style>
